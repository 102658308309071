var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-table",
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Action")
              ]),
              _c(
                "gov-table-cell",
                [
                  _c(
                    "gov-tag",
                    { class: "action action--" + _vm.audit.action },
                    [_vm._v(_vm._s(_vm.audit.action))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Description")
              ]),
              _c("gov-table-cell", [_vm._v(_vm._s(_vm.audit.description))])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Guest / User")
              ]),
              _c("gov-table-cell", [
                _vm._v(
                  _vm._s(
                    _vm.audit.user
                      ? _vm.audit.user.first_name +
                          " " +
                          _vm.audit.user.last_name
                      : "Guest"
                  )
                )
              ])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Client")
              ]),
              _c("gov-table-cell", [
                _vm._v(_vm._s(_vm.audit.oauth_client || "-"))
              ])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Date / Time")
              ]),
              _c("gov-table-cell", [
                _vm._v(_vm._s(_vm.formatDateTime(_vm.audit.created_at)))
              ])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("IP Address")
              ]),
              _c("gov-table-cell", [_vm._v(_vm._s(_vm.audit.ip_address))])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("User Agent")
              ]),
              _c("gov-table-cell", [
                _vm._v(_vm._s(_vm.audit.user_agent || "-"))
              ])
            ],
            1
          ),
          _c(
            "gov-table-row",
            [
              _c("gov-table-header", { attrs: { top: "", scope: "row" } }, [
                _vm._v("Scheduled for deletion")
              ]),
              _c("gov-table-cell", [
                _vm._v(
                  _vm._s(
                    _vm.formatDate(_vm.autoDeleteDate(_vm.audit.updated_at))
                  )
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }